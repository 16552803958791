<template lang="html">
  <div class="card dashboard">
    <header class="card-header">
      <p class="card-header-title">
        <router-link to="/categories" class="has-text-dark">
          <span class="icon is-medium">
            <icon 
              icon="book-open-variant" 
              size="m" />
          </span>
          <span class="has-text-weight-light is-size-4 ml-2">Bladeren</span>
        </router-link>
      </p>
    </header>
    <div class="card-content">
      <div class="columns is-gapless">
        <div class="column has-text-centered">
          <router-link to="/categories">
            <span class="tag is-rounded is-medium" :style="badgeStyle">
              <i class="mdi mdi-book-open-variant has-text-white"></i>
            </span>
          </router-link>
        </div>
      </div>
    </div>
    <footer class="card-footer">
      <router-link to="/categories" class="card-footer-item">Bladeren</router-link>
    </footer>
  </div>
</template>

<script>

import icon from '../../common/icon'

export default {
  name: 'categories',
  computed: {
    badgeStyle: function () {
      return { 
        'background-color': this.$root.$data.theme.buttonColor,
        'color': 'white'
      }
    }
  },
  components: {
    'icon': icon
  }
}
</script>
