<template lang="html">
  <article class="message">
    <div class="message-body">   
      <div class="content">
        <span class="icon is-medium">
          <i :class="`mdi mdi-${icon}`"></i>
        </span>
        <h2 class="is-size-4 has-text-weight-light">{{title}}</h2>
        <p>{{message}}</p>
      </div>
    </div>
  </article>
</template>

<script>

export default {
  name: 'noEntityAutocomplete',
  props: [
    'title',
    'button',
    'icon',
    'message',
    'createPath'
  ]
}
</script>