<template lang="html">
  <div>
    <div v-for="paragraph in paragraphs" v-bind:key="paragraph.id">
      <h1 class="has-text-weight-light is-size-4">{{ paragraph.name }}</h1>
      <div class="container">
        
        <!-- paragraph without image -->
        <p class="content" v-if="!paragraph.has_image">
          <vue-markdown
              :source="paragraph.content"
              :anchorAttributes="{
                target: '_blank'
              }"
          />
        </p>
      
        <!-- paragraph with image -->
        <div class="columns" v-if="paragraph.has_image">
          <div class="column is-8">
            <p class="content">
              <vue-markdown :source="paragraph.content" />
            </p>
          </div>
          <div class="column is-4">
            <figure class="image">
              <img class="is-rounded-corner" :src="imageUrl(paragraph)" />
            </figure>
          </div>
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>

import VueMarkdown from 'vue-markdown'
import * as Cookies from 'tiny-cookie'

export default {
  name: 'paragraphs',
  methods: {
    imageUrl: function (paragraph) {
      // handle archiving through entity abstraction layer
      var token = Cookies.get('access_token')
      return process.env.VUE_APP_API_URL + '/paragraph/' + paragraph.id + '/image/file?access_token=' + token + '&client=web'
    }
  },
  props: [
    'paragraphs'
  ],
  components: {
    'vue-markdown': VueMarkdown
  }
}
</script>
