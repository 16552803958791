<template lang="html">
  <a class="button" v-on:click.prevent="click()" :style="colorStyle" :disabled="disabled">
    <span class="icon">
      <i class="mdi mdi-content-save"></i>
    </span>
    <span>Opslaan</span>
  </a>
</template>

<script>

export default {
  name: 'genericButton',
  props: [
    'icon',
    'size',
    'color',
    'text',
    'disabled'
  ],
  methods: {
    click: function () {
      this.$emit('click')
    }
  },
  computed: {
    sizeStyle: function () {
      switch (this.size) {
        case 's':

          return 'mdi-18px'
        
        case 'm':

          return 'mdi-24px'

        case 'l':

          return 'mdi-36px'

        case 'xl':

          return 'mdi-48px'

        default:

          return ''
      }
    },
    colorStyle: function () {
      if (this.color) {
        return { 
          'background-color': this.color, 
          'color': 'white'
        }
      }

      return { 
        'background-color': this.$root.$data.theme.buttonColor, 
        'color': 'white',
        'border-color': this.$root.$data.theme.buttonColor
      }
    }
  }
}

</script>
