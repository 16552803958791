<template lang="html">
  <div id="notification">
    <div class="container">
      <div class="columns">
        <div class="column is-12">
          <h1 class="has-text-weight-light title is-size-3">{{ notification.title }}</h1>
        </div>
      </div>

      <div class="columns">
        <div class="column is-12">
          <a class="button is-medium" v-on:click="goBack()">
            <span class="icon">
              <i class="mdi mdi-arrow-left"></i>
            </span>
            <span>Terug</span>
          </a>
        </div>
      </div>

      <div class="columns">
        <div class="column is-12">
          <div class="card">
            <header class="card-header">
              <div class="card-header-title">
                <article class="media">
                  <div class="media-left">
                    <avatar
                      :username="writer.name"
                      class="avatar"
                      v-if="writer.name"
                      :size="35"
                      backgroundColor="#1B9DC1"
                      color="#FFFFFF">
                    </avatar>
                  </div>
                  <div class="media-content">
                    <div class="content p-t-xs">
                      <p>
                        <strong>{{ writer.name }}</strong>
                        <small class="has-text-weight-light"> | {{ notification.created_at | moment("from", "now") }}</small>
                      </p>
                    </div>
                  </div>
                </article>
              </div>
            </header>
            <div class="card-content">
              <p>{{ notification.content }}</p>
              <hr />

              <div class="container has-text-centered" v-if="notification.document_id">
                <router-link :to="'/document/' + notification.document_id" tag="a" class="button is-rounded is-medium">
                  <span class="icon"><i class="mdi mdi-link-variant" aria-hidden="true"></i></span>
                  <span>Bekijk {{ notification.document.data.name }}</span>
                </router-link>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import Avatar from 'vue-avatar'

export default {
  name: 'notification',
  data: function () {
    return {
      notification: {},
      writer: false
    }
  },
  methods: {
    getNotification: function (notificationId) {
      this.$bus.$emit('loadingEvent', true)
      this.$api.get('me/notification/' + notificationId + '?include=document').then((response) => {
        this.notification = response.data.data

        if (this.notification.writer) {
          this.writer = this.notification.writer.data
        }

        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    goBack: function () {
      this.$router.go(-1)
    }
  },
  mounted: function () {
    this.getNotification(this.$route.params.id)
  },
  beforeDestroy () {},
  components: {
    'avatar': Avatar
  }
}
</script>