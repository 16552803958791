<template lang="html">
  <div class="section p-0">
    <div class="section is-horizontal-center p-0">
      <img src="../../assets/logo-login.svg" style="width: 16em" class="mt-5 mb-4" />
    </div>
    <div class="card mt-4">
      <div class="card-content">
        <div class="content">
          <div class="field">
            <label class="label">E-mailadres</label>
            <div class="control">
              <input class="input" type="email" disabled :value="passwordReset.email" placeholder="E-mailadres">
            </div>
            <p v-for="(error, index) in errors.email" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Wachtwoord</label>
            <div class="control">
              <input class="input" type="password" ref="password" v-model="passwordReset.password" placeholder="Wachtwoord" v-on:keyup.enter="reset()">
            </div>
            <p v-for="(error, index) in errors.password" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <div class="field">
            <label class="label">Wachtwoord (bevestiging)</label>
            <div class="control">
              <input class="input" type="password" v-model="passwordReset.password_confirmation" placeholder="Wachtwoord (bevestiging)" v-on:keyup.enter="reset()">
            </div>
            <p v-for="(error, index) in errors.password_confirmation" class="help is-danger" :key="index">{{ error }}</p>
          </div>
          <a class="button is-primary" v-on:click="reset()" v-show="!resetting">Wachtwoord instellen</a>
          <a class="button is-primary is-disabled" v-show="resetting">Bezig met instellen</a>
          <router-link class="button is-pulled-right" to="/login">
            <span class="icon">
              <i class="mdi mdi-arrow-left"></i>
            </span>
            <span>Terug naar inloggen</span>
          </router-link>
        </div>
        <div class="content" v-if="failed">
          <article class="message is-small is-danger">
            <div class="message-body">
              <strong>Helaas: </strong>het instellen van een nieuw wachtwoord is mislukt. Probeer opnieuw een  <router-link to="/password-forgot">nieuw wachtwoord aan te vragen</router-link>.
            </div>
          </article>
        </div>
        <div class="content" v-if="success">
          <article class="message is-info">
            <div class="message-body">
              <strong>Gelukt! </strong>Je nieuwe wachtwoord is ingesteld, <router-link to="/login">je kunt opnieuw inloggen</router-link>.
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>
export default {
  name: 'passwordReset',
  data: function () {
    return {
      passwordReset: {
        email: '',
        password: '',
        password_confirmation: '',
        token: ''
      },
      resetting: false,
      errors: {},
      failed: false,
      success: false
    }
  },
  methods: {
    reset: function () {
      // emit reset in event
      this.resetting = true

      this.$api.post('/password-reset', this.passwordReset).then((response) => {
        // validation failed
        if (response.data.errors) {
          this.errors = response.data.errors
          this.$notify({
            type: 'warning',
            title: 'Reset mislukt',
            text: 'Probeer opnieuw een nieuw wachtwoord in te stellen'
          })
          this.failed = false
        }
        // password reset failed
        if (response.data.message === 'password_reset_failed') {
          this.failed = true
        }
        // password reset completed
        if (response.data.message === 'password_reset_success') {
          this.success = true
          this.failed = false
          this.errors = {}
        }

        this.resetting = false
      }, (error) => {
        this.resetting = false
        this.showApiErrorNotification(error)
      })
    }
  },
  mounted: function () {
    this.$refs.password.focus()

    // retrieve url query params
    this.passwordReset.email = this.$route.query.email
    this.passwordReset.token = this.$route.query.token
  }
}
</script>
