<template lang="html">

  <div class="container">

    <div class="columns">
      <div class="column">

        <scope-switch 
          wide-scope-text="Gelezen berichten"
          wide-scope-url="/notifications/viewed" 
          wide-scope-icon="check-all"
          small-scope-text="Ongelezen berichten"
          small-scope-url="/notifications" 
          small-scope-icon="bell-outline"
          :is-wide="viewed" />
          
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <notifications-card :notifications="filteredNotifications" title="Berichten" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import notificationsCard from '../common/notifications-card'
import scopeSwitch from '../common/scope-switch'

export default {
  name: 'notifications',
  data: function () {
    return {
      notifications: [],
      showViewed: false
    }
  },
  props: [
    'viewed'
  ],
  methods: {
    getNotifications: function () {
      this.$bus.$emit('loadingEvent', true)
      this.$api.get('me/notifications').then((response) => {
        for (let notification of response.data.data) {
          this.notifications.push({
            id: notification.id,
            title: notification.title,
            created_at: notification.created_at,
            type: notification.type,
            is_viewed: notification.is_viewed
          })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    toggleFilter: function () {
      if (this.showViewed === false) {
        this.showViewed = true
      } else {
        this.showViewed = false
      }
    }
  },
  mounted: function () {
    this.getNotifications()
  },
  computed: {
    filteredNotifications: function () {
      if (!this.viewed) {
        return this.notifications.filter((notification) => {
          if (!notification.is_viewed) return true
        })
      }

      return this.notifications.filter((notification) => {
        if (notification.is_viewed) return true
      })
    }
  },
  components: {
    'notifications-card': notificationsCard,
    'scope-switch': scopeSwitch
  }
}
</script>
