const Entities = {
  install (Vue) {
    // initiate global prototyp
    Vue.prototype.$entity = {}

    let api
    // set global api client to use
    Vue.prototype.$entity.setApi = function (apiClient) {
      api = apiClient
    }

    let eventBus
    // send out an event
    const emitEvent = function (event, payload) {
      if (eventBus) {
        eventBus.$emit(event, payload)
      }
    }

    // set global eventbus to use
    Vue.prototype.$entity.setEventBus = function (bus) {
      eventBus = bus
    }

    let notificationBus
    // send out an event
    const emitNotification = function (notification) {
      if (notificationBus) {
        notificationBus(notification)
      }
    }

    // set global notifications bus to use
    Vue.prototype.$entity.setNotificationBus = function (bus) {
      notificationBus = bus
    }

    /*
     * Generic entity rating method
     *
     * entityType: name of the entity, to display messages
     * entityId: identification of the entity to unpublish
     * rating: the rating for this entity
     * endpoint: api endpoint responsible
     */
    Vue.prototype.$entity.rate = function (entityType, entityName, entityId, rating) {
      emitEvent('ratingEvent', true)
      emitEvent('loadingEvent', true)
      let entityTypeKey = entityType + '_id'
      let ratingData = {
        [entityTypeKey]: entityId,
        'rating': rating
      }
      api.post('ratings/', ratingData).then((response) => {
        if (response.data.code && response.data.code > 200) {
          emitEvent('loadingEvent', false)
          emitEvent('ratingEvent', false)
          emitNotification({
            type: 'danger',
            title: entityName + ' is niet beoordeeld',
            text: 'Probeer nogmaals de actie uit te voeren'
          })
        } else {
          emitEvent('loadingEvent', false)
          emitEvent('ratingEvent', false)
          emitNotification({
            type: 'success',
            title: entityName + ' beoordeeld',
            text: entityName + ' is succesvol beoordeeld'
          })
        }
      }, (error) => {
        emitEvent('loadingEvent', false)
        emitEvent('ratingEvent', false)
        emitEvent('apiErrorEvent', error)
      })
    }

    /*
     * Generic entity comment method
     *
     * entityType: name of the entity, to display messages
     * entityId: identification of the entity to unpublish
     * comment: the comment for this entity
     * endpoint: api endpoint responsible
     */
    Vue.prototype.$entity.comment = function (entityType, entityName, entityId, comment) {
      emitEvent('commentingEvent', true)
      emitEvent('loadingEvent', true)
      let entityTypeKey = entityType + '_id'
      let commentData = {
        [entityTypeKey]: entityId,
        'comment': comment
      }
      api.post('comments/', commentData).then((response) => {
        if (response.data.code && response.data.code > 200) {
          emitEvent('loadingEvent', false)
          emitEvent('commentingEvent', false)
          emitNotification({
            type: 'danger',
            title: 'Commentaar voor ' + entityName + ' niet ingestuurd',
            text: 'Probeer nogmaals de actie uit te voeren'
          })
        } else {
          emitEvent('loadingEvent', false)
          emitEvent('commentingEvent', false)
          emitNotification({
            type: 'success',
            title: 'Commentaar ingestuurd',
            text: 'Commentaar voor ' + entityName + ' is succesvol ingestuurd'
          })
        }
      }, (error) => {
        emitEvent('loadingEvent', false)
        emitEvent('commentingEvent', false)
        emitEvent('apiErrorEvent', error)
      })
    }
  }
}

export default Entities
