<template lang="html">

  <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        <span class="icon is-medium">
          <i class="mdi mdi-key-outline has-text-grey"></i>
        </span>
        <span class="has-text-weight-light is-size-4 ml-2">Wachtwoord wijzigen</span>
      </p>
    </header>
    <div class="card-content">
      <div class="field">
        <label class="label">Wachtwoord</label>
        <div class="control">
          <input class="input" type="password" v-model="me.password" placeholder="Kies nieuw wachtwoord"
            v-bind:class="{ 'is-danger': errors.password }"
            v-bind:disabled="isSaving"
            v-on:keyup.enter="updateWriter()">
        </div>
        <p v-for="(error, index) in errors.password" class="help is-danger" :key="index">{{ error }}</p>
      </div>
      <div class="field">
        <label class="label">Wachtwoord (bevestiging)</label>
        <div class="control">
          <input class="input" type="password" v-model="me.password_confirmation" placeholder="Bevestig het gekozen wachtwoord"
            v-bind:class="{ 'is-danger': errors.password_confirmation }"
            v-bind:disabled="isSaving"
            v-on:keyup.enter="updateWriter()">
        </div>
        <p v-for="(error, index) in errors.password_confirmation" class="help is-danger" :key="index">{{ error }}</p>
      </div>

      <generic-button 
        text="Opslaan" 
        icon="content-save"
        @click="updatePassword" 
        :disabled="isSaving" />
        
    </div>
  </div>

</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import genericButton from '../common/generic-button'

export default {
  name: 'me',
  data: function () {
    return {
      me: {},
      errors: {},
      isSaving: false
    }
  },
  methods: {
    getMe: function () {
      this.$bus.$emit('loadingEvent', true)
      this.$api.get('me').then((response) => {
        this.me = response.data.data
        this.$bus.$emit('loadingEvent', false)
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    updatePassword: function () {
      this.$bus.$emit('savingEvent', true)
      this.isSaving = true
      this.$api.put('me/password', this.me).then((response) => {
        if (response.data.code && response.data.code > 200) {
          this.$bus.$emit('savingEvent', false)
          this.isSaving = false
          this.errors = response.data.errors
          this.$notify({
            type: 'warning',
            title: 'Wachtwoord niet gewijzigd',
            text: 'Controleer de invoervelden en probeer opnieuw'
          })
        } else {
          this.tag = response.data.data
          this.$bus.$emit('savingEvent', false)
          this.isSaving = false
          this.$notify({
            type: 'success',
            title: 'Wachtwoord gewijzigd',
            text: 'Je wachtwoord is succesvol aangepast'
          })
          this.errors = {}
          this.$router.push('/')
        }
      }, () => {
        this.$bus.$emit('savingEvent', false)
        this.isSaving = false
      })
    }
  },
  mounted: function () {
    this.getMe()
  },
  components: {
    'generic-button': genericButton
  }
}
</script>
