<template lang="html">
  <div class="container">

    <scope-switch 
      wide-scope-text="Alle informatie"
      :wide-scope-url="'/tag/' + tag.id + '/global'" 
      wide-scope-icon="infinity"
      small-scope-text="Informatie voor jou"
      :small-scope-url="'/tag/' + tag.id"
      small-scope-icon="account"
      :is-wide="global" />

    <div class="columns">
      <div class="column">
        <router-link to="/tags" class="button is-medium">
          <span class="icon">
            <i class="mdi mdi-arrow-left"></i>
          </span>
          <span>Terug</span>
        </router-link>
      </div>

     </div>

    <div class="columns">
      <div class="column is-12">

        <entities-card :entities="entities" :title="'Informatie rondom ' + tag.name" />

      </div>
    </div>

  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import entitiesCard from '../common/entities-card'
import scopeSwitch from '../common/scope-switch'

export default {
  name: 'tag',
  data: function () {
    return {
      entities: [],
      tag: {
        name: ''
      }
    }
  },
  props: [
    'global'
  ],
  methods: {
    getTag: function (tagId) {
      this.$bus.$emit('loadingEvent', true)
      this.$api.get('tag/' + tagId).then((response) => {
        this.tag = response.data.data

        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    getTagEntities: function (tagId) {
      this.$bus.$emit('loadingEvent', true)
      this.$api.get('tag/' + tagId + '/entities').then((response) => {
        this.entities = []
        for (let entity of response.data.data) {
          this.entities.push({
            id: entity.id,
            name: entity.name,
            type: entity.type,
            entity_type: entity.entity_type,
            tags: entity.tags.data,
            updated_at: entity.updated_at })
        }

        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    getTagEntitiesPersonal: function (tagId) {
      this.$bus.$emit('loadingEvent', true)
      this.$api.get('tag/' + tagId + '/personal/entities').then((response) => {
        this.entities = []
        for (let entity of response.data.data) {
          this.entities.push({
            id: entity.id,
            name: entity.name,
            type: entity.type,
            entity_type: entity.entity_type,
            tags: entity.tags.data,
            updated_at: entity.updated_at })
        }

        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    }
  },
  mounted: function () {
    this.getTag(this.$route.params.id)

    if (this.global) {
      this.getTagEntities(this.$route.params.id)
    } else {
      this.getTagEntitiesPersonal(this.$route.params.id)
    }
  },
  watch: {
    '$route' (to) {
      if (!this.global) {
        this.getTagEntitiesPersonal(to.params.id)
      } else {
        this.getTagEntities(to.params.id)
      }
    },
    'global': {
      // watch for global or personal results request
      handler: function (global) {
        if (global) {
          this.getTagEntities(this.$route.params.id)
        } else {
          this.getTagEntitiesPersonal(this.$route.params.id)
        }
      }
    }
  },
  components: {
    'entities-card': entitiesCard,
    'scope-switch': scopeSwitch
  }
}
</script>
