import Vue from 'vue'
import Router from 'vue-router'

import home from '../components/home/home'

import login from '../components/login/login'
import passwordForgot from '../components/login/password-forgot'
import passwordReset from '../components/login/password-reset'

import categories from '../components/categories/categories'
import category from '../components/categories/category'

import tags from '../components/tags/tags'
import tag from '../components/tags/tag'

import bookmarks from '../components/bookmarks/bookmarks'

import me from '../components/me/me'

import changelog from '../components/changelog/changelog'

import document from '../components/document/document'

import notifications from '../components/notification/notifications'
import notification from '../components/notification/notification'

Vue.use(Router)

// application routes
const routes = [
  { path: '/', component: home },

  { path: '/login', component: login },
  { path: '/password-forgot', name: 'passwordForgot', component: passwordForgot },
  { path: '/password-reset', name: 'passwordReset', component: passwordReset },

  { path: '/categories', component: categories, name: 'categories', props: { global: false } },
  { path: '/categories/global', component: categories, props: { global: true } },
  { path: '/category/:id', component: category, name: 'category', props: { global: false } },
  { path: '/category/:id/global', component: category, props: { global: true } },

  { path: '/tags', component: tags, props: { global: false } },
  { path: '/tags/global', component: tags, props: { global: true } },
  { path: '/tag/:id', component: tag, props: { global: false } },
  { path: '/tag/:id/global', component: tag, props: { global: true } },

  { path: '/bookmarks', component: bookmarks },

  { path: '/me', component: me },

  { path: '/changelog', component: changelog },
  { path: '/changelog/global', component: changelog, props: { global: true } },

  { path: '/document/:id', component: document, name: 'document' },
  { path: '/document/:id/global', component: document, props: { global: true } },

  { path: '/notifications', component: notifications },
  { path: '/notifications/viewed', component: notifications, props: { viewed: true} },
  { path: '/notification/:id', component: notification }
]

// export router instance
export default new Router({
  mode: 'history',
  routes,
  linkActiveClass: 'is-active'
})
