<template lang="html">
  <i :class="'mdi mdi-' + icon + ' ' + sizeStyle" :style="colorStyle"></i>
</template>

<script>

export default {
  name: 'button',
  props: [
    'icon',
    'size',
    'color',
    'text'
  ],
  computed: {
    sizeStyle: function () {
      switch (this.size) {
        case 's':

          return 'mdi-18px'
        
        case 'm':

          return 'mdi-24px'

        case 'l':

          return 'mdi-36px'

        case 'xl':

          return 'mdi-48px'

        default:

          return ''
      }
    },
    colorStyle: function () {
      if (this.color) {
        return { 'color': this.color }
      }

      return { 'color': this.$root.$data.theme.buttonColor }
    }
  }
}

</script>
