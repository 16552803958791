<template lang="html">
  <div class="tabs is-centered is-medium is-toggle">
    <ul>
      <li>
          <router-link :to="smallScopeUrl" v-bind:style="[!isWide ? switcherActiveStyle : '' ]">
          <span class="icon is-medium"><i :class="'mdi mdi-' + smallScopeIcon" aria-hidden="true"></i></span>
          <span>{{ smallScopeText }}</span>
        </router-link>
      </li>
      <li>
        <router-link :to="wideScopeUrl" v-bind:style="[isWide ? switcherActiveStyle : '' ]">
          <span class="icon is-medium"><i :class="'mdi mdi-' + wideScopeIcon" aria-hidden="true"></i></span>
          <span>{{ wideScopeText }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'scopeSwitch',
  props: [
    'smallScopeText',
    'smallScopeUrl',
    'wideScopeText',
    'wideScopeUrl',
    'smallScopeIcon',
    'wideScopeIcon',
    'isWide'
  ],
  computed: {
    switcherActiveStyle: function () {
      return { 
        'background-color': this.$root.$data.theme.backgroundColor, 
        'color': 'white',
        'border-color': this.$root.$data.theme.backgroundColor
      }
    }
  },
}
</script>
