<template lang="html">
  <div class="container">

    <scope-switch 
      entities-name="thema's" 
      wide-scope-text="Alle informatie"
      wide-scope-url="/tags/global" 
      wide-scope-icon="infinity"
      small-scope-text="Informatie voor jou"
      small-scope-url="/tags" 
      small-scope-icon="account"
      :is-wide="global" />

    <div class="columns" v-for="i in rowCount" v-bind:key="i">
      <div class="column" v-for="tag in tags.slice((i - 1) * 4, i * 4)" v-bind:key="tag.id">
        <router-link :to="/tag/ + tag.id + urlScope">
          <div class="card">
            <div class="card-content has-text-centered">
              <span class="icon is-large">
                <i :class="'mdi mdi-' + tag.icon + ' mdi-48px'" :style="{color: tag.color}"></i>
              </span>
              <h1>{{ tag.name }}</h1>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <no-entity
      title="Geen thema's gevonden"
      icon="book"
      :count="tags.length" />

  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import noEntity from '../common/no-entity'
import scopeSwitch from '../common/scope-switch'

export default {
  name: 'tags',
  data: function () {
    return {
      tags: [],
      urlScope: ''
    }
  },
  props: [
    'global'
  ],
  methods: {
    getTagsPersonal: function () {
      this.$bus.$emit('loadingEvent', true)
      this.$api.get('tags/personal').then((response) => {
        this.tags = []
        for (let tag of response.data.data) {
          this.tags.push({
            id: tag.id,
            name: tag.name,
            color: tag.color,
            icon: tag.icon })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    getTags: function () {
      this.$bus.$emit('loadingEvent', true)
      this.$api.get('tags').then((response) => {
        this.tags = []
        for (let tag of response.data.data) {
          this.tags.push({
            id: tag.id,
            name: tag.name,
            color: tag.color,
            icon: tag.icon })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    }
  },
  mounted: function () {
    if (this.global) {
      this.getTags(this.$route.params.id)
      this.urlScope = '/global'
    } else {
      this.getTagsPersonal(this.$route.params.id)
      this.urlScope = ''
    }
  },
  computed: {
    rowCount: function () {
      return Math.ceil(this.tags.length / 4)
    }
  },
  components: {
    'no-entity': noEntity,
    'scope-switch': scopeSwitch
  },
  watch: {
    '$route' (to) {
      if (!this.global) {
        this.getTagsPersonal(to.params.id)
        this.urlScope = '/global'
      } else {
        this.getTags(to.params.id)
        this.urlScope = ''
      }
    },
    'global': {
      // watch for global or personal results request
      handler: function (global) {
        if (global) {
          this.urlScope = '/global'
          this.getTags(this.$route.params.id)
        } else {
          this.urlScope = ''
          this.getTagsPersonal(this.$route.params.id)
        }
      }
    }
  }
}
</script>
