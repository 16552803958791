<template lang="html">
  <div class="section p-0">
    <div class="section is-horizontal-center p-0">
      <img src="../../assets/logo-login.svg" style="width: 16em" class="mt-5 mb-4" />
    </div>
    <div class="card mt-4">
      <div class="card-content">
        <div class="content">
          <h3>Inloggen</h3>
          <div class="field">
            <label class="label">E-mailadres</label>
            <div class="control">
              <input class="input" type="email" v-model="reader.username" ref="email" placeholder="E-mailadres" autofocus required>
            </div>
          </div>
          <div class="field">
            <label class="label">Wachtwoord</label>
            <div class="control">
              <input class="input" type="password" v-model="reader.password" placeholder="Wachtwoord" v-on:keyup.enter="login()" required>
            </div>
          </div>
          <a class="button is-primary" v-on:click="login" v-show="!loggingIn">
            <span class="icon">
              <i class="mdi mdi-login-variant"></i>
            </span>
            <span>Inloggen</span>
          </a>
          <a class="button is-primary is-disabled" v-show="loggingIn">Bezig met inloggen</a>
          <router-link class="button is-pulled-right" to="/password-forgot">
            <span class="icon">
              <i class="mdi mdi-help-circle"></i>
            </span>
            <span>Wachtwoord vergeten?</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>
export default {
  name: 'login',
  data: function () {
    return {
      reader: {
        username: null,
        password: null
      },
      loggingIn: false
    }
  },
  methods: {
    login: function () {
      this.$bus.$emit('askReaderLoginEvent', this.reader)
      this.errors = {}
      this.loggingIn = true
    },
    setLoggedIn: function () {
      this.loggingIn = false
    },
    setFailed: function () {
      this.$notify({
        type: 'warning',
        title: 'Inloggen mislukt',
        text: 'De combinatie van gebruikersnaam en wachtwoord is onjuist.'
      })
      this.loggingIn = false
    },
    setError: function () {
      this.$notify({
        type: 'warning',
        title: 'Inloggen mislukt',
        text: 'Er is een fout opgetreden bij het inloggen. Probeer het later opnieuw.'
      })
      this.loggingIn = false
    }
  },
  mounted: function () {
    this.$bus.$on('apiLoggedInEvent', this.setLoggedIn)
    this.$bus.$on('apiInvalidCredentialsEvent', this.setFailed)
    this.$bus.$on('apiInvalidRequestEvent', this.setFailed)
    this.$bus.$on('apiInvalidClientEvent', this.setLoggedIn)
    this.$bus.$on('apiErrorEvent', this.setError)

    // focus on email field
    this.$refs.email.focus()

    document.body.style.backgroundColor = 'rgba(0,187,224,1)'
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('apiLoggedInEvent', this.setLoggedIn)
    this.$bus.$off('apiInvalidCredentialsEvent')
    this.$bus.$off('apiInvalidRequestEvent')
    this.$bus.$off('apiInvalidClientEvent', this.setLoggedIn)
    this.$bus.$off('apiErrorEvent', this.setError)
  }
}
</script>
