<template lang="html">
  <div class="container">

    <div class="section is-horizontal-center p-0">
      <div class="logo" :style="{ 'background-image': 'url(' + logoUrl + ')' }"></div>
      <hr />
      <span class="is-size-4 has-text-weight-light has-text-white pt-5">Welkom {{ reader.name }}</span>
      <span class="is-size-5 has-text-grey mb-5">{{ reader.positions }}</span>
    </div>

    <search />

    <div class="columns">
      <div class="column is-3">

        <categories />

      </div>
      <div class="column is-3">

        <tags />

      </div>
      <div class="column is-3">

        <bookmarks />

      </div>
      <div class="column is-3">

        <notifications />

      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import categories from './childs/categories'
import tags from './childs/tags'
import bookmarks from './childs/bookmarks'
import notifications from './childs/notifications'
import search from '../common/search'

export default {
  name: 'home',
  data: function () {
    return {
      logoUrl: '',
      reader: {
        name: '',
        positions: ''
      }
    }
  },
  mounted: function () {
    // get reader name from local storage
    this.reader.name = localStorage.getItem('name')
    this.reader.positions = localStorage.getItem('positions')
    // compose logo url
    let accessToken = localStorage.getItem('access_token')

    this.logoUrl = process.env.VUE_APP_API_URL + '/theme/logo/vector/web?access_token=' + accessToken
  },
  beforeDestroy () {
    // stop listening
  },
  computed: {
    readerName: function () {
      if (this.reader && this.reader.name) return this.reader.name

      return ''
    }
  },
  components: {
    'categories': categories,
    'tags': tags,
    'bookmarks': bookmarks,
    'notifications': notifications,
    'search': search
  }
}
</script>
