<template lang="html">
<div>
  
  <navbar />

  <notifications :duration=3500 :speed=500>
       <template slot="body" slot-scope="props">
        <article class="message is-floating" :class="'is-'+props.item.type">
          <div class="message-header">
            <p>{{props.item.title}}</p>
          </div>
          <div class="message-body has-text-left ">
            {{ props.item.text }}
          </div>
        </article>
      </template>
  </notifications>
  
  <section class="section curved pt-4">
    <div class="container">

      <router-view></router-view>

    </div>
  </section>
</div>
</template>

<script>
export default {
  name: 'App'
}
</script>