<template lang="html">
  <div class="modal" v-bind:class="{ 'is-active': visible }">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box">
        <video-player class="video-player-box"
           ref="videoPlayer"
           :options="playerOptions"
           :playsinline="true">
        </video-player>
        <hr />
        <a class="button" v-on:click="close()">
          <span class="icon">
            <i class="mdi mdi-close"></i>
          </span>
          <span>Venster sluiten</span>
        </a>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" v-on:click="close()"></button>
  </div>
</template>

<script>

import 'video.js/dist/video-js.css'

import * as Cookies from 'tiny-cookie'
import { videoPlayer } from 'vue-video-player'

export default {
  name: 'videoModal',
  data: function () {
    return {
      visible: false,
      videoUrl: '',
      playerOptions: {
        muted: false,
        language: 'nl',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        width: 600,
        sources: false
        // poster: "/static/images/author.jpg",
      }
    }
  },
  methods: {

    close: function () {
      this.playerOptions.muted = true
      this.visible = false
    },
    spawnModal: function (attachment) {
      // get token from cookie
      var token = Cookies.get('access_token')
      // set player options
      this.playerOptions.muted = false
      this.playerOptions.sources = [{
        type: attachment.type,
        src: process.env.VUE_APP_API_URL + '/attachment/' + attachment.id + '/file?access_token=' + token
      }]
      this.visible = true
    }
  },
  mounted: function () {
    // listen for ask for modal event
    this.$bus.$on('askAttachmentVideoEvent', this.spawnModal)
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askAttachmentVideoEvent')
  },
  components: {
    'video-player': videoPlayer
  }
}
</script>
