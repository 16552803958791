<template lang="html">
  <div>
    <video-modal />

    <multi-select
      v-model="selection"
      id="ajax"
      ref="search"
      label="name"
      track-by="id"
      placeholder="Type om te zoeken naar artikelen, bijlages & categorieën"
      selectLabel=""
      :block-keys="['Enter']"
      open-direction="bottom"
      :options="entities"
      :multiple="true"
      :searchable="true"
      :internal-search="false"
      :preserve-search="true"
      :clear-on-select="false"
      :close-on-select="false"
      :options-limit="300"
      :loading="loading"
      :limit="3"
      :max-height="600"
      :show-no-results="true"
      :show-no-options="false"
      :hide-selected="true"
      @search-change="inputSearch"
      class="search">
      <template slot="option" slot-scope="props">
        <div class="columns is-gapless result">
          <div class="column is-8">
            <div class="has-text-dark" v-on:click.stop="goToEntity(props.option)">
              <h2 class="subtitle is-size-7">
                {{ getEntityTypeLabel(props.option.entity_type) }}
              </h2>
              <h1 class="has-text-weight-light title is-size-5">
                <icon :icon="getEntityIcon(props.option)" size="s" :color="props.option.color" class="mr-1"/>
                {{ props.option.name }}
              </h1>
            </div>
          </div>
        </div>
      </template>
      <no-entity-autocomplete
          title="Geen zoekresultaten"
          message="Probeer andere zoektermen."
          icon="magnify"
          slot="noResult"
          v-if="!loading" />
      <no-entity-autocomplete
          title="Geen zoekresultaten"
          message="Probeer andere zoektermen."
          icon="magnify"
          slot="noOptions"
          v-if="!loading" />
    </multi-select>
  </div>
</template>

<style lang="scss">
  @import '~vue-multiselect/dist/vue-multiselect.min.css';
</style>

<script>

import _ from 'lodash'
import Multiselect from 'vue-multiselect'
import noEntityAutocomplete from './no-entity-autocomplete'
import videoModal from '../common/video-modal'
import icon from '../common/icon'

export default {
  name: 'search',
  data () {
    return {
      entities: [],
      keywords: '',
      selection: [],
      result: [],
      loading: false
    }
  },
  methods: {
    getLabel (item) {
      return item.name
    },
    inputSearch: function (keywords) {
      this.typing = true
      this.keywords = keywords
    },
    getSearch: function (text) {
      // only search with at least some text
      if (text.length > 0) {
        this.$bus.$emit('loadingEvent', true)
        this.loading = true

        let searchData = {
          keywords: text
        }

        this.$api.post('search/entities', searchData).then((response) => {
          this.entities = []
          for (let entity of response.data.data) {
            this.entities.push({
              entity_type: entity.entity_type,
              id: entity.id,
              name: entity.name,
              color: entity.color,
              icon: entity.icon,
              type: entity.type
            })
          }

          this.$bus.$emit('loadingEvent', false)
          this.loading = false
        }, (error) => {
          this.$bus.$emit('loadingEvent', false)
          this.loading = false
          this.$bus.$emit('apiErrorEvent', error)
        })
      } else {
        // empty result set on empty search text
        this.entities = []
      }
    },
    goToEntity: function (entity) {
      switch (entity.entity_type) {
        case 'document':

          return this.$router.push({ name: 'document', params: { id: entity.id } })

        case 'attachment':

          return this.downloadAttachment(entity)

        case 'category':

          return this.$router.push({ name: 'category', params: { id: entity.id } })

        default:

          return ''
      }
    },
    getEntityTypeLabel: function (entityType) {
      switch (entityType) {
        case 'document':

          return 'Artikel'

        case 'attachment':

          return 'Bijlage'

        case 'category':

          return 'Categorie'

        default:

          return ''
      }
    },
    getEntityIcon: function (entity) {
      switch (entity.entity_type) {
        case 'document':

          return 'file-document'

        case 'attachment':

          return 'paperclip'

        case 'category':

          return entity.icon

        default:

          return ''
      }
    },
    downloadAttachment: function (attachment) {
      if (attachment.type === 'video/mp4') {
        this.attachmentVideoModal(attachment)

        this.$refs.search.deactivate()
      } else {
        let accessToken = localStorage.getItem('access_token')
        var rnd = new Date().getTime()
        window.location.href = process.env.VUE_APP_API_URL + '/attachment/' + attachment.id + '/file?access_token=' + accessToken + '&client=web&rnd=' + rnd
      }
    },
    attachmentVideoModal: function (attachment) {
      this.$bus.$emit('askAttachmentVideoEvent', attachment)
    },
  },
  mounted: function () {
    // perform initial search when keywords are set (from previous search action)
    if (this.$root.$data.search.keywords && this.$root.$data.search.keywords.length > 0) {
      this.keywords = this.$root.$data.search.keywords

      this.$refs.search.activate()
    }
  },

  watch: {
    keywords: _.debounce(function () {
      this.typing = false
      // perform a search
      this.getSearch(this.keywords)
      // store search keywords
      this.$root.$data.search.keywords = this.keywords
    }, 500)
  },
  components: {
    'multi-select': Multiselect,
    'no-entity-autocomplete': noEntityAutocomplete,
    'video-modal': videoModal,
    'icon': icon
  }
}
</script>
