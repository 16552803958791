<template lang="html">
  <div class="container">

    <scope-switch 
      wide-scope-text="Alle wijzigingen"
      wide-scope-url="/changelog/global" 
      wide-scope-icon="infinity"
      small-scope-text="Wijzigingen voor jou"
      small-scope-url="/changelog" 
      small-scope-icon="account"
      :is-wide="global" />

    <documents-card :documents="documents" title="Laatst gewijzigde artikelen" />

  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import documentsCard from '../common/documents-card'
import scopeSwitch from '../common/scope-switch'

export default {
  name: 'changelog',
  data: function () {
    return {
      documents: []
    }
  },
  props: [
    'global'
  ],
  methods: {
    getChangelogPersonal: function () {
      this.$bus.$emit('loadingEvent', true)
      this.$api.get('changelog/personal?include=tags').then((response) => {
        this.documents = []
        for (let document of response.data.data) {
          this.documents.push({
            id: document.id,
            name: document.name,
            average_rating: document.average_rating,
            tags: document.tags.data,
            updated_at: document.updated_at,
            last_change_at: document.last_change_at })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    getChangelog: function () {
      this.$bus.$emit('loadingEvent', true)
      this.$api.get('changelog?include=tags').then((response) => {
        this.documents = []
        for (let document of response.data.data) {
          this.documents.push({
            id: document.id,
            name: document.name,
            average_rating: document.average_rating,
            tags: document.tags.data,
            updated_at: document.updated_at,
            last_change_at: document.last_change_at })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    }
  },
  mounted: function () {
    this.getChangelogPersonal()
  },
  watch: {
    'global': {
      // watch for global or personal results request
      handler: function (global) {
        if (global) {
          this.getChangelog()
        } else {
          this.getChangelogPersonal()
        }
      }
    }
  },
  components: {
    'documents-card': documentsCard,
    'scope-switch': scopeSwitch
  }
}
</script>
